import React from "react"
import { graphql } from "gatsby"
import { Creature } from "../../types/Creature"
import { serializers } from "../../shared/serializers"
import Image from "gatsby-plugin-sanity-image"
import BlockContent from "@sanity/block-content-to-react"

import Layout from "../../components/layout"
import SpeciesTable from "./components/species-table"
import CreatureTable from "./components/creature-table"

interface PageData {
  data: {
    creature: Creature
  }
}

const CreatureTemplate: React.FC<PageData> = ({ data }) => {
  const { creature } = data
  // console.log("creaturesssss", serializers)

  return (
    <Layout>
      <div className="bg-white bg-texture overflow-hidden">
        <div className="relative max-w-6xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div className="mx-auto text-base max-w-prose lg:max-w-none">
            <h3 className="text-base sm:text-lg text-gray-700 font-semibold tracking-wide uppercase">
              {
                //creature.type.replace(/_/g, " ")
              }
            </h3>
            <h1 className="mt-2 text-4xl font-bold font-display tracking-tight text-primary sm:text-6xl">
              {creature.name}
            </h1>
          </div>

          <div className="mt-8 lg:mt-3 lg:grid lg:grid-cols-2 lg:gap-8">
            <div className="relative lg:row-start-1 lg:col-start-2">
              <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
                <figure>
                  <div className="aspect-w-2 aspect-h-3">
                    <Image
                      {...creature.image}
                      alt={`${creature.name} Image`}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </figure>
              </div>
            </div>
            <div className="mt-8 lg:-mt-4">
              <div className="text-lg prose prose-indigo text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
                <BlockContent
                  blocks={creature._rawFlavorText}
                  serializers={serializers}
                />
              </div>
            </div>
          </div>

          <div className="mt-6">
            <div className="flex flex-col">
              <h3 className="mt-2 text-3xl font-bold font-display tracking-tight text-primary sm:text-5xl">
                Stat Table
              </h3>

              {/* {creature.type !== "species"
                ? <CreatureTable creature={creature} />
                : <SpeciesTable creature={creature} />
              } */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CreatureTemplate

export const query = graphql`
  query($slug: String!) {
    creature: sanityCreature(slug: { current: { eq: $slug } }) {
      _key
      name
      type {
        name
      }
      hit_points
      common_level
      lifespan
      size
      speed
      reach
      marksmanship
      strength
      defense
      attack
      stamina
      agility
      might
      will
      fate
      charisma
      courage
      dexterity
      fortitude
      guile
      intelligence
      lore_points
      perception
      stealth
      special_feat
      _rawOther
      _rawLore
      _rawSpells
      _rawDetails
      _rawModifiers
      _rawFlavorText
      image {
        ...ImageWithPreview
      }
    }
  }
`
